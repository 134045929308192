import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAhvKTgo4tRQr8-p8Pnndn-M2M7_Q0lDR0",
  authDomain: "apt-walkin-interview.firebaseapp.com",
  projectId: "apt-walkin-interview",
  storageBucket: "apt-walkin-interview.appspot.com",
  messagingSenderId: "684020253099",
  appId: "1:684020253099:web:c04b34a995a826c61a52ee",
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const storage = getStorage(firebase);


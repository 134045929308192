import React, { useEffect, useState } from "react";
import { isAuth } from "../utils/authUtils";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
export default function AuthLayout() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth()) {
      navigate("/");
    }
  }, []);
  return <Outlet></Outlet>;
}

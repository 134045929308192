import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../assets/layout-header.png";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

export default function DialInEnd() {
  let [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    // if (urlParams.get("uid")) {
    //   localStorage.setItem("uid", String(urlParams.get("uid")));
    // }
    // if (!localStorage.getItem("uid")) {
    //   navigate("/xmas/redeem");
    // }
  }, []);

  return (
    <>
      <div style={{ gridArea: "2 / 1 / 3 / 4" }}>
        {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
        <img
          src={layoutHeaderImg}
          alt="layoutHeaderImg"
          style={{ maxWidth: "100%" }}
        ></img>
        {/* </div> */}
      </div>
      <div
        style={{ gridColumn: "2 / 3", gridRow: "2 / 2" }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "var(--focus-primary-color)",
          }}
        >
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>Thank you</div>
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>We shall</div>
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>
            update your
          </div>
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>
            interview status
          </div>
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>within 48</div>
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>hours.</div>
        </div>
      </div>
      <div style={{ gridColumn: "2 / 3" }}>
        <div
          style={{
            color: "var(--focus-primary-color)",
            textAlign: "center",
            marginBottom: "10px"
          }}
        >
          or contact your Career Consultant
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
            marginBottom: "10px",
          }}
        >
          <CustomButton
            onClick={() => {
              navigate("/consultant");
            }}
            text={"Contact consultant"}
            color="white"
            backgroundColor="#1caf11"
            style={{
              marginBottom: "10px",
            }}
          ></CustomButton>
        </div>
      </div>
    </>
  );
}

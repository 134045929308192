import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { useState, useEffect } from "react";
import { getAllCandidates } from "../../services/getCandidate";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getStoredCandidate,
  storeCandidate,
  storeResponseSheetName,
} from "../../utils/authUtils";
import LoadingComponent from "../../components/LoadingComponent";
export default function DialInCheckIn() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [btnState, setBtnState] = useState({
    text: "Continue",
    backgroundColor: "var(--primary-color)",
  });
  const [searchParams, _] = useSearchParams();

  const btnOff = () =>
    setBtnState({
      text: "...",
      backgroundColor: "grey",
    });
  const btnOn = () =>
    setBtnState({
      text: "Continue",
      backgroundColor: "var(--primary-color)",
    });

  const onSubmit = () => {
    // btnOff();
    // getAllCandidates()
    //     .then(({ data, error }) => {
    //         if (error) {
    //             throw new Error(error);
    //         }
    //         let candidate = data.find((x) => x.email == email);
    //         if (!candidate) {
    //             alert(
    //                 "Email not found, please use email that you used to submit application"
    //             );
    //             btnOn();
    //             return;
    //         }
    //         storeCandidate(candidate);
    //         navigate("/checkin");
    //         btnOn();
    //     })
    //     .catch((err) => {
    //         console.trace(err);
    //         alert("Unable to get candidates");
    //         btnOn();
    //     });
  };

  useEffect(() => {
    const run = async () => {
      // Forced refresh everytime
      // try {
      //   if (getStoredCandidate()) {
      //     navigate("/checkin");
      //     return;
      //   }
      // } catch (e) {}
      // TODO: Hmm?
      if (
        searchParams.get("candidateId") &&
        searchParams.get("responseSheetName")
      ) {
        let { data, error } = await getAllCandidates(
          String(searchParams.get("responseSheetName"))
        );

        let candidate = data.find(
          (x) => x.candidateId == searchParams.get("candidateId")
        );
        if (!candidate) {
          setLoading(false);
          return;
        }

        storeCandidate(candidate);
        storeResponseSheetName(String(searchParams.get("responseSheetName")));
        navigate("/checkin");
      } else {
        setLoading(false);
      }
    };

    run()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <SimpleIphonePromptLayout>
      {loading ? (
        <LoadingComponent></LoadingComponent>
      ) : (
        <>Please use provided link in your email to access this page</>
      )}
    </SimpleIphonePromptLayout>
  );
}

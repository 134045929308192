import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import LoadingComponent from "../../components/LoadingComponent";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import { getStoredResponseSheetName } from "../../utils/authUtils";

export default function DialInGoogleMeet() {
    const navigate = useNavigate();
    const [meetInfo, setMeetInfo] = useState({
        meetLink: "https://meet.google.com/vpp-vhja-vwo",
        formLink:
            "https://docs.google.com/forms/d/1BB40-QpXTsW-OQ477h63EfRCVaQIKjVtzQit87q_1uo",
    });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const run = async () => {
            const { data: setting, error } = await getSettings(
                getStoredResponseSheetName()
            );
            if (error) {
                console.error("Unable to get settings");
                return;
            }

            const { setting_google_meet_link, setting_pwa_gform_link } =
                setting;
            if (setting_pwa_gform_link && setting_google_meet_link) {
                setMeetInfo({
                    meetLink: setting.setting_google_meet_link,
                    formLink: setting.setting_pwa_gform_link,
                });
            }
        };
        run()
            .then(() => setLoading(false))
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, []);
    return (
        <SimpleIphonePromptLayout>
            {loading ? (
                <LoadingComponent></LoadingComponent>
            ) : (
                <>
                    <div
                        style={{
                            padding: "20px 0",
                            fontWeight: "bold",
                            fontSize: "48px",
                        }}
                    >
                        Join our <br />
                        Google <br />
                        Meet Now! <br />
                    </div>
                    <div style={{ color: "grey" }}>
                        Tap to join the "Dial in Interview" NOW
                    </div>
                    <CustomButton
                        onClick={() => {
                            window.open(meetInfo.meetLink);
                            navigate("/interviewCompletedPrompt");
                        }}
                        color="var(--primary-color)"
                        backgroundColor="var(--focus-primary-color)"
                        text="Google Meet Link"
                    ></CustomButton>

                    <CustomButton
                        onClick={() => {
                            window.open(meetInfo.formLink);
                        }}
                        color="var(--focus-primary-color)"
                        backgroundColor="rgb(233,233,233)"
                        text="Reschedule"
                    ></CustomButton>
                    <div style={{ fontWeight: "normal", fontStyle: "italic" }}>
                        <span style={{ color: "red" }}>*</span> Kindly return to
                        this app after your interview!
                    </div>
                </>
            )}
        </SimpleIphonePromptLayout>
    );
}

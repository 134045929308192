import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getStoredCandidate, getStoredResponseSheetName } from "../../utils/authUtils";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import updateCandidate from "../../services/updateCandidate";
const StyledHeader = styled.div`
    font-size: 1.5rem;
    color: var(--focus-primary-color);
`;

const focusStyle = {
    color: "#81838a",
    fontSize: "1.25rem",
    fontWeight: "bold",
};

export default function DialInInterviewInput() {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        interviewerName: "",
        file: "",
    });

    const onSubmit = async () => {
        if (submitting) return;
        setSubmitting(true);
        try {
            let snapshot: any = null;
            if (formData.file) {
                const file = formData.file as any;
                // Upload to firebase
                const storageRef = ref(
                    storage,
                    `/files/${getStoredCandidate()?.email}-interviewer-${
                        file.name
                    }`
                );
                snapshot = await uploadBytes(storageRef, file);
            }

            updateCandidate(
                getStoredResponseSheetName(),
                String(getStoredCandidate()?.candidateId),
                {
                    interviewerName: formData.interviewerName,
                    dialInInterviewScreen: snapshot
                        ? await getDownloadURL(snapshot.ref)
                        : "",
                }
            );
        } catch (e) {
            console.error(e);
            setSubmitting(false);
        }
        setSubmitting(false);
        navigate("/endOfInterview");
        navigate("/interviewCompleted");
    };
    return (
        <SimpleIphonePromptLayout>
            <StyledHeader>Name of Candidate</StyledHeader>
            <div style={focusStyle}>{getStoredCandidate()?.name}</div>
            <div>Interviewer Details</div>
            <StyledHeader>Name of Interviewer</StyledHeader>
            <CustomInput
                style={focusStyle}
                placeholder="Name of interviewer"
                onChange={(evt: any) =>
                    setFormData((old: any) => ({
                        ...old,
                        interviewerName: evt.target.value,
                    }))
                }
                type="text"
            ></CustomInput>
            <StyledHeader>Upload Dial-In Interview screen</StyledHeader>
            <CustomInput
                style={{
                    ...focusStyle,
                    fontWeight: "normal",
                    fontSize: "1rem",
                }}
                placeholder=""
                onChange={(evt: any) => {
                    setFormData((old: any) => ({
                        ...old,
                        file: evt.target.files[0],
                    }));
                }}
                type="file"
            ></CustomInput>

            <CustomButton
                onClick={onSubmit}
                backgroundColor="var(--primary-color)"
                text={submitting ? "Submitting..." : "Submit"}
            ></CustomButton>
        </SimpleIphonePromptLayout>
    );
}

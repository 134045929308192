import React from "react";
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";

import WalkinLayout from "./layouts/WalkinLayout";

import "bootstrap/dist/css/bootstrap.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "./components/LoadingComponent";
import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";
import DialInCheckIn from "./pages/dialin/DialInCheckIn";
import DialInCongraz from "./pages/dialin/DialInCongraz";
import DialInGoogleMeet from "./pages/dialin/DialInGoogleMeet";
import DialInJoinSoon from "./pages/dialin/DialInJoinSoon";
import DialInConsultant from "./pages/dialin/DialInConsultant";
import DialInInterviewPrompt from "./pages/dialin/DialInInterviewPrompt";
import DialInInterviewInput from "./pages/dialin/DialInInterviewInput";
import DialInInterviewComplete from "./pages/dialin/DialInInterviewComplete";
import DialInFeedback from "./pages/dialin/DialInFeedback";
import DialInEnd from "./pages/dialin/DialInEnd";
import AuthLayout from "./layouts/AuthLayout";
const solidIconList = Object.keys(solidIcons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon: string) => (solidIcons as any)[icon]);

const brandsIconList = Object.keys(BrandsIcons)
  .filter((key) => key !== "fab" && key !== "prefix")
  .map((icon: string) => (BrandsIcons as any)[icon]);

library.add(...solidIconList);
library.add(...brandsIconList);

const router = createBrowserRouter([
  {
    path: "/",
    element: <WalkinLayout></WalkinLayout>,
    children: [
      {
        path: "",
        element: <DialInCheckIn></DialInCheckIn>,
      },
      {
        path: "*",
        element: <AuthLayout></AuthLayout>,
        children: [
          {
            path: "checkin",
            element: <DialInCongraz></DialInCongraz>,
          },
          {
            path: "consultant",
            element: <DialInConsultant></DialInConsultant>,
          },
          {
            path: "joinsoon",
            element: <DialInJoinSoon></DialInJoinSoon>,
          },
          {
            path: "gmeet",
            element: <DialInGoogleMeet></DialInGoogleMeet>,
          },
          {
            path: "interviewCompletedPrompt",
            element: <DialInInterviewPrompt></DialInInterviewPrompt>,
          },
          {
            path: "interviewerInput",
            element: <DialInInterviewInput></DialInInterviewInput>,
          },
          {
            path: "interviewCompleted",
            element: <DialInInterviewComplete></DialInInterviewComplete>,
          },
          {
            path: "feedback",
            element: <DialInFeedback></DialInFeedback>,
          },
          {
            path: "endOfInterview",
            element: <DialInEnd></DialInEnd>,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingComponent></LoadingComponent>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

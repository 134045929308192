import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../assets/layout-header.png";

import CustomButton from "../../components/CustomButton";

import CustomInput from "../../components/CustomInput";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import updateCandidate from "../../services/updateCandidate";
import {
    getStoredCandidate,
    getStoredResponseSheetName,
} from "../../utils/authUtils";
export default function DialInFeedback() {
    const [formData, setFormData] = useState({
        feedback: "",
        file: "",
    });
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const submitFeedback = async () => {
        if (submitting) return;
        setSubmitting(true);
        try {
            let snapshot: any = null;
            if (formData.file) {
                const file = formData.file as any;
                // Upload to firebase
                const storageRef = ref(
                    storage,
                    `/files/${getStoredCandidate()?.email}-feedback-${file.name}`
                );
                snapshot = await uploadBytes(storageRef, file);
            }

            updateCandidate(
                getStoredResponseSheetName(),
                String(getStoredCandidate()?.candidateId),
                {
                    anythingToShare: formData.feedback,
                    feedbackUploads: snapshot
                        ? await getDownloadURL(snapshot.ref)
                        : "",
                }
            );
        } catch (e) {
            console.error(e);
            setSubmitting(false);
        }
        setSubmitting(false);
        navigate("/endOfInterview");
    };

    return (
        <>
            <div style={{ gridArea: "2 / 1 / 3 / 4" }}>
                {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
                <img
                    src={layoutHeaderImg}
                    alt="layoutHeaderImg"
                    style={{ maxWidth: "100%" }}
                ></img>
                {/* </div> */}
            </div>
            <div
                style={{ gridColumn: "2 / 3", gridRow: "2 / 2" }}
                className="zoom-out-anim"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        alignItems: "center",
                        height: "100%",
                        color: "var(--focus-primary-color)",
                    }}
                >
                    <div style={{ fontSize: "3rem", textAlign: "center" }}>
                        Anything you
                    </div>
                    <div
                        style={{
                            fontSize: "3rem",
                            textAlign: "center",
                            marginBottom: "30px",
                        }}
                    >
                        like to share?
                    </div>
                    <div
                        style={{
                            // marginBottom: "30px",
                            width: "100%",
                        }}
                    >
                        <textarea
                            onChange={(evt) =>
                                setFormData((old) => ({
                                    ...old,
                                    feedback: evt.target.value,
                                }))
                            }
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                padding: "10px",
                                border: "0px white solid",
                                textAlign: "center",
                                height: "10em",
                                marginBottom: "10px",
                            }}
                            placeholder="Comment here !"
                        ></textarea>
                        <div
                            style={{
                                color: "var(--focus-primary-color)",
                                textAlign: "center",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            Upload Image
                        </div>
                        <CustomInput
                            style={{
                                backgroundColor: "#ececef",
                                marginBottom: "30px",
                            }}
                            placeholder=""
                            type="file"
                            onChange={(evt: any) => {
                                setFormData((old) => ({
                                    ...old,
                                    file: evt.target.files[0],
                                }));
                            }}
                        ></CustomInput>
                    </div>
                </div>
            </div>
            <div style={{ gridColumn: "2 / 3" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "10px",
                        width: "100%",
                        gridColumn: "2 / 3",
                        marginBottom: "10px",
                    }}
                >
                    <CustomButton
                        onClick={submitFeedback}
                        text={submitting ? "Submiting" : "Submit"}
                        color="var(--primary-color)"
                        backgroundColor="var(--focus-primary-color)"
                        style={{
                            marginBottom: "10px",
                        }}
                    ></CustomButton>
                    <CustomButton
                        onClick={() => {
                            navigate("/endOfInterview");
                        }}
                        text="Skip"
                        color="var(--focus-primary-color)"
                        backgroundColor="#ececef"
                    ></CustomButton>
                </div>
            </div>
        </>
    );
}

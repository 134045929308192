import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomSelect from "../../components/CustomSelect";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import { getStoredResponseSheetName } from "../../utils/authUtils";
import LoadingComponent from "../../components/LoadingComponent";

export default function DialInJoinSoon() {
  const [isLoading, setIsLoading] = useState(true);
  const [soonTime, setSoonTime] = useState("");
  const navigate = useNavigate();
  const [coordinatorPhone, setCoordinatorPhone] = useState("");
  const [useStaticLink, setUseStaticLink] = useState({
    link: "",
    use: false,
  });
  useEffect(() => {
    (async () => {
      const settings = await getSettings(getStoredResponseSheetName());
      if (!settings) {
        throw new Error("Unable to get settings");
      }
      console.log(JSON.stringify(settings));
      const match = String(settings?.data?.setting_whatsapp_link).match(
        /phone=([0-9]*)&?/
      );
      if (!match) {
        console.log("Using static link");
        setUseStaticLink({
          link: settings?.data?.setting_whatsapp_link,
          use: true,
        });
        setIsLoading(false);
        return;
      }

      if (!match[1]) {
        console.log("Using static link");
        setUseStaticLink({
          link: settings?.data?.setting_whatsapp_link,
          use: true,
        });
        setIsLoading(false);
        return;
      }

      setCoordinatorPhone(match[1]);
      setIsLoading(false);
    })();
  }, []);
  return (
    <SimpleIphonePromptLayout>
      {isLoading ? (
        <LoadingComponent></LoadingComponent>
      ) : (
        <>
          <div
            style={{ padding: "20px 0", fontWeight: "bold", fontSize: "48px" }}
          >
            How soon <br />
            may you join
            <br />
            a "Dial In <br />
            Interview" <br />
          </div>
          {/* <CustomButton
        onClick={() => null}
        text="Please select one"
        color="var(--focus-primary-color)"
        backgroundColor="var(--primary-color)"
      ></CustomButton> */}
          <CustomSelect
            onChange={(e: any) => {
              // let index = e.nativeEvent.target.selectedIndex;
              // let selectedText = e.nativeEvent.target[index].text
              setSoonTime(e.target.value);
            }}
            choices={[
              { key: "5", value: "5 minutes" },
              { key: "10", value: "10 minutes" },
              { key: "20", value: "20 minutes" },
              { key: "30", value: "30 minutes" },
            ]}
          ></CustomSelect>
          <CustomButton
            onClick={() => {
              if (!soonTime.endsWith("minutes")) {
                alert("Please select one.");
                return;
              }

              window.open(
                useStaticLink.use
                  ? useStaticLink.link
                  : `https://api.whatsapp.com/send?phone=${coordinatorPhone}&text=I will be ready in ${soonTime} for interview.`
              );
              navigate("/gmeet");
            }}
            text="Notify coordinator (whatsapp)"
            color="var(--primary-color)"
            backgroundColor="var(--focus-primary-color)"
          ></CustomButton>
        </>
      )}
    </SimpleIphonePromptLayout>
  );
}

export default function CustomButton(props: {
  onClick: any;
  backgroundColor: any;
  text: any;
  color?: any;
  style?: any;
}) {
  const extraStyles = props.style ? props.style : {}
  return (
    <div
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        textAlign: "center",
        color: props.color ? props.color : "var(--focus-primary-color)",
        backgroundColor: props.backgroundColor,
        borderRadius: "10px",
        padding: "10px",
        width: "100%",
        ...extraStyles
      }}
    >
      {props.text}
    </div>
  );
}

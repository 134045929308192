import rootUrl from "../APIRoot";
import { Candidate } from "../../api/src/interfaces/Candidate";

export default async function updateCandidate(
  sheetTitle: string,
  candidateId: string,
  newCandidateData: Partial<Candidate>
): Promise<{
  data: any;
  error: any;
}> {
  let req = await fetch(`${rootUrl}/candidates/${sheetTitle}/${candidateId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newCandidateData),
  });

  let { data, error } = await req.json();
  return { data, error };
}

import bannerImg from "./../../assets/layout-header.png";

import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingComponent from "./../../components/LoadingComponent";
import { Link } from "react-router-dom";
import { getUID, getStoredCandidate } from "../../utils/authUtils";
import { Candidate } from "../../../api/src/interfaces/Candidate";
import CustomButton from "../../components/CustomButton";

function CustomField(props: any) {
  return (
    <div style={{ marginBottom: "10px", width: "100%", textAlign: "center" }}>
      <div style={{ color: "white" }}>{props.title}</div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          color: "var(--focus-primary-color)",
        }}
      >
        {props.text}
      </div>
    </div>
  );
}

export default function DialInCongraz() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  let [candidateData, setCandidateData] = useState<Partial<Candidate>>(
    getStoredCandidate() as Candidate
  );

  const processInterviewDateTime = (idt: string | any) => {
    if (!idt) return { date: "", time: "" };
    let arr = idt.split("-");
    let time = arr.slice(-2).join("-");
    let date = arr.slice(0, -2).join("-");
    return { date, time };
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);
  return isLoading ? (
    <LoadingComponent style={{ gridColumn: "2 / 3" }}></LoadingComponent>
  ) : (
    <>
      <div
        style={{
          gridArea: "2 / 2 / 3 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "var(--focus-primary-color)",
          }}
        >
          <h1 style={{ fontSize: "32px" }}>Congratulations!</h1>
        </div>
        <div
          style={{
            borderRadius: "30px",
            border: "3px solid white",
            backgroundColor: "var(--focus-primary-color)",
            padding: "40px 30px",
            marginLeft: "10px",
            marginRight: "10px",
            // height: "100%",
            color: "black",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "large",
              color: "white",
            }}
          >
            You have been shortlisted for
            <br />
            "Dial-In Interview"
          </div>
          <img
            alt="ticket-img"
            style={{
              maxHeight: "200px",
              maxWidth: "100%",
              marginBottom: "20px",
            }}
            src={bannerImg}
          ></img>
          <div
            style={{
              color: "var(--primary-color)",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Interview Details
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomField
              title="Time"
              text={
                processInterviewDateTime(candidateData?.interviewDateTime).time
              }
            ></CustomField>
            <CustomField
              title="Date"
              text={
                processInterviewDateTime(candidateData?.interviewDateTime).date
              }
            ></CustomField>
          </div>
        </div>
      </div>
      <div
        style={{ gridColumn: "2 / 3", margin: "0 10px", marginBottom: "20px" }}
      >
        <Link to="/consultant" style={{ textDecoration: "none" }}>
          <CustomButton
            onClick={() => {}}
            backgroundColor="white"
            text={"Click here to to get started!"}
          ></CustomButton>
        </Link>
      </div>
    </>
  );
}

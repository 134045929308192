import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import updateCandidate from "../../services/updateCandidate";
import {
    getStoredCandidate,
    getStoredResponseSheetName,
} from "../../utils/authUtils";

export default function DialInInterviewPrompt() {
    const navigate = useNavigate();
    return (
        <SimpleIphonePromptLayout>
            <div
                style={{
                    padding: "20px 0",
                    fontWeight: "bold",
                    fontSize: "48px",
                }}
            >
                Have you <br />
                completed <br />
                your
                <br />
                interview?
            </div>
            <CustomButton
                onClick={() => {
                    navigate("/interviewerInput");
                }}
                backgroundColor="var(--primary-color)"
                text="Yes"
                color="var(--focus-primary-color)"
            ></CustomButton>
            <CustomButton
                onClick={() => {
                    updateCandidate(
                        getStoredResponseSheetName(),
                        String(getStoredCandidate()?.candidateId),
                        {
                            quitToWithdraw: "QUITTED",
                        }
                    );
                    navigate("/interviewCompleted");
                }}
                backgroundColor="#ececef"
                text="Quit To Withdraw"
                color="var(--focus-primary-color)"
            ></CustomButton>
            <CustomButton
                onClick={() => {
                    navigate("/gmeet");
                }}
                backgroundColor="#1caf11"
                text="Technical Issue"
                color="var(--primary-color)"
            ></CustomButton>
        </SimpleIphonePromptLayout>
    );
}

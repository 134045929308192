import React from "react";
import layoutHeaderImg from "../assets/layout-header.png";
export default function SimpleIphonePromptLayout(props: {
  children: React.ReactNode;
}) {
  return (
    <>
      <div
        style={{
          gridColumn: "1 / 4",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            margin: "20px 0",
            maxWidth: "100%",
            maxHeight: "300px",
          }}
          alt="layout-header"
          src={layoutHeaderImg}
        ></img>
      </div>
      {/* Popup */}
      <div
        className="prompt-box"
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          alignItems: "center",
          textAlign: "center",
          gridColumn: "1 / 4",
          color: "black",
          backgroundColor: "white",
          borderRadius: "50px 50px 0 0",
          padding: "20px 30px",
        }}
      >
        {props.children}
      </div>
    </>
  );
}

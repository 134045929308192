import Cookies from "universal-cookie";
import { Candidate } from "../../api/src/interfaces/Candidate";
export function storeUID(uid: string) {
    localStorage.setItem("uid", uid);
    const cookies = new Cookies();
    cookies.set("uid", uid, { path: "/" });
}

export function getUID() {
    return localStorage.getItem("uid");
}

export function isAuth() {
    return getStoredCandidate();
}

export function logOff() {
    localStorage.removeItem("uid");
    const cookies = new Cookies();
    cookies.remove("uid", { path: "/" });
}

export function storeCandidate(candidate: Candidate) {
    localStorage.setItem("candidate", JSON.stringify(candidate));
}

export function storeResponseSheetName(rsn: string) {
    localStorage.setItem("responseSheetName", rsn);
}

export function getStoredResponseSheetName() {
    return String(localStorage.getItem("responseSheetName"));
}

export function getStoredCandidate() {
    if (!localStorage.getItem("candidate")) return null;
    return JSON.parse(String(localStorage.getItem("candidate"))) as Candidate;
}

export function removeStoredCandidate() {
    localStorage.removeItem("candidate");
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../assets/layout-header.png";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { maxWidth } from "@mui/system";
import updateCandidate from "../../services/updateCandidate";
import { getStoredCandidate, getStoredResponseSheetName } from "../../utils/authUtils";
export default function DialInInterviewComplete() {
  const [rating, setRating] = useState(3);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();

  const submitRate = () => {
    updateCandidate(
      getStoredResponseSheetName(),
      String(getStoredCandidate()?.candidateId), {
      rating: String(rating),
    });
    navigate("/feedback");
  };

  useEffect(() => {
    // if (urlParams.get("uid")) {
    //   localStorage.setItem("uid", String(urlParams.get("uid")));
    // }
    // if (!localStorage.getItem("uid")) {
    //   navigate("/xmas/redeem");
    // }
  }, []);

  return (
    <>
      <div style={{ gridArea: "2 / 1 / 3 / 4" }}>
        {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
        <img
          src={layoutHeaderImg}
          alt="layoutHeaderImg"
          style={{ maxWidth: "100%" }}
        ></img>
        {/* </div> */}
      </div>
      <div
        style={{ gridColumn: "2 / 3", gridRow: "2 / 2" }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "var(--focus-primary-color)",
          }}
        >
          <div style={{ fontSize: "3rem", textAlign: "center" }}>You have</div>
          <div style={{ fontSize: "3rem", textAlign: "center" }}>completed</div>
          <div
            style={{
              fontSize: "3rem",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            your interview.
          </div>
          <div
            style={{
              marginBottom: "30px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              columnGap: "10px",
            }}
          >
            {[1, 2, 3, 4, 5].map((x) => (
              <FontAwesomeIcon
                key={`rating-star-${x}`}
                onClick={() => setRating(x)}
                style={{
                  color: rating >= x ? "#ffabdb" : "white",
                  cursor: "pointer",
                }}
                size={"2xl"}
                icon={["fas", "star"]}
              ></FontAwesomeIcon>
            ))}
          </div>
          <div>Self rate your interview performance</div>
        </div>
      </div>
      <div style={{ gridColumn: "2 / 3" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
            marginBottom: "10px",
          }}
        >
          <CustomButton
            onClick={submitRate}
            text={submitting ? "Submiting" : "Submit"}
            color="var(--focus-primary-color)"
            backgroundColor="#ececef"
          ></CustomButton>
        </div>
      </div>
    </>
  );
}

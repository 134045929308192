import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import banner from "../assets/banner.png";
import {
  getStoredCandidate,
  getUID,
  logOff,
  removeStoredCandidate,
} from "../utils/authUtils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const MenuList = [
  {
    text: "Check in via Email",
    faIcon: ["fas", "fa-arrow-right-from-bracket"],
    link: "/",
  },
  {
    text: "Get Started",
    faIcon: ["fas", "fa-flag-checkered"],
    link: "/checkin",
  },
  {
    text: "Your Consultant",
    faIcon: ["fas", "question"],
    link: "/consultant",
  },
  {
    text: "Join Google Meet",
    faIcon: ["fas", "handshake"],
    link: "/joinsoon",
  },
  // { text: "Join GMeet", faIcon: ["fas", "house"], link: "/gmeet" },
  {
    text: "Complete Interview",
    faIcon: ["fas", "list"],
    link: "/interviewCompletedPrompt",
  },
  // { text: "Complete Interview", faIcon: ["fas", "house"], link: "/interviewerInput" },
  {
    text: "Rate your interview",
    faIcon: ["fas", "star"],
    link: "/interviewCompleted",
  },
  // { text: "Feedback", faIcon: ["fas", "house"], link: "/feedback" },
  // { text: "End of Interview", faIcon: ["fas", "house"], link: "/endOfInterview" },
];

export default function WalkinLayout() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [updatingUsername, setUpdatingUsername] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  useEffect(() => {
    try {
      let candidate = getStoredCandidate();
      if (candidate) {
        setCandidateName(candidate.name);
      }
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <div
      style={{
        color: "white",
        display: "grid",
        gridTemplateColumns: "20px 1fr 20px",
        gridTemplateRows: "auto 1fr auto",
        height: "100%",
        backgroundColor: "var(--primary-color)",
        paddingTop: "20px",
        fontWeight: "bold",
      }}
    >
      <div></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40px 1fr 100px",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={["fas", "bars"]}
            ></FontAwesomeIcon>
          </IconButton>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <div
                style={{
                  padding: "20px",
                  color: "var(--focus-primary-color)",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <img
                  onClick={() => {
                    toggleDrawer(false);
                    setTimeout(() => navigate("/xmas/checkinevent"), 500);
                  }}
                  src={banner}
                  style={{ maxWidth: "100%" }}
                  alt="side-banner"
                ></img>
                <div>Welcome!</div>
                <div style={{ fontWeight: "bold" }}>{candidateName}</div>
                {candidateName ? (
                  <>
                    <div
                      onClick={() => {
                        removeStoredCandidate();
                        setCandidateName("");
                        navigate("/");
                      }}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Log off
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <Divider></Divider>
              <List>
                {MenuList.map(({ text, faIcon, link }) => (
                  <ListItem key={`menu-list-${text}`} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        toggleDrawer(false);
                        setTimeout(() => navigate(link), 500);
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon size="xl" icon={faIcon as IconProp} />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
                {/* <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleDrawer(false);
                      setTimeout(() => navigate("/walkin/checkin"), 500);
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        size="xl"
                        icon={["fas", "arrow-right-from-bracket"]}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Check in"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleDrawer(false);
                      setTimeout(() => navigate("/walkin/location"), 500);
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        size="xl"
                        icon={["fas", "location-dot"]}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Check in location"} />
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Box>
          </Drawer>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={banner} alt="decorTop" style={{ maxHeight: "60px" }} />
        </div>
        <div>
          <Link to="/consultant" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                color: "#25D366",
                backgroundColor: "white !important",
              }}
              variant="contained"
              startIcon={
                <FontAwesomeIcon icon={["fab", "whatsapp"]}></FontAwesomeIcon>
              }
            >
              Help
            </Button>
          </Link>
        </div>
      </div>
      <div></div>
      <Outlet></Outlet>
    </div>
  );
}
